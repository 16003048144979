var ReviewsContainer;
var ReviewsArray = [];
var CurrentReview = 0;
$(document).on('click', '#ReviewContainer #ReviewButtonsList .ReviewButtonsListItem', ChangeCurrentReview);
$(document).ready(function(){
    if($('#ReviewList').length){
        getReviews($('#ReviewList'));
    }
});
function getReviews(Elem){
    ReviewsContainer = Elem;
    ReviewsContainer.empty();
    ReviewsContainer.parent().find('#ReviewButtonsList').empty();
    ReviewsArray = [];
    CurrentReview = 0;
    PostMethodFunction('/WebPagePetitions/Reviews/Get', {}, null, ShowReviews, null);
}
function ShowReviews(response){
    ReviewsArray = response;
    if(ReviewsArray.length>0){
        var AppendContent = '';
        for (let index = 0; index < ReviewsArray.length; index++) {
            AppendContent += '<li class="ReviewButtonsListItem" value="'+index+'">';
                AppendContent += '<img width="1" height="1" style="height: auto" class="ReviewButtonsListItemImage" src="/images/review_button.webp" alt="Reseña">';
            AppendContent += '</li>';
        }
        ReviewsContainer.parent().find('#ReviewButtonsList').append(AppendContent);
        AppendContent = '';
        $(ReviewsArray).each(function(index, review) {
            AppendContent += '<li class="ReviewListItem ReviewListItemNotView" value="'+index+'">';
                AppendContent += '<p class="ReviewListItemAuthor">'+review.author+'</p>';
                AppendContent += '<ul class="ReviewListItemStarList">';
                for (let index = 0; index < review.rating; index++) {
                    AppendContent += '<li class="ReviewListItemStarListItem">';
                        AppendContent += '<img width="1" height="1" style="height: auto" class="ReviewListItemStarListItemImage" src="/images/star.webp" alt="Rating">';
                    AppendContent += '</li>';
                }
                AppendContent += '</ul>';
                AppendContent += '<p class="ReviewListItemParagraph" title="'+review.text+'">"'+review.text_showed+'"</p>';
            AppendContent += '</li>';
        });
        ReviewsContainer.append(AppendContent);
        ShowCurrentReview();
    }else{
        ReviewsContainer.parent().remove();
    }
}
function ShowCurrentReview(){
    $('#ReviewContainer #ReviewButtonsList .ReviewButtonsListItem .ReviewButtonsListItemImage').attr('src', '/images/review_button.webp');
    $('#ReviewContainer #ReviewButtonsList .ReviewButtonsListItem[value="'+CurrentReview+'"] .ReviewButtonsListItemImage').attr('src', '/images/review_button_selected.webp');
    $('#ReviewContainer #ReviewList .ReviewListItem').removeClass('ReviewListItemView').addClass('ReviewListItemNotView');
    $('#ReviewContainer #ReviewList .ReviewListItem[value="'+CurrentReview+'"]').addClass('ReviewListItemView');
}
function ChangeCurrentReview(event){
    event.preventDefault();
    CurrentReview = $(this).attr('value');
    ShowCurrentReview();
}